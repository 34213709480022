.marginTop {
    margin-top: 20px;
}

.label-bold {
    font-weight: bold;
}

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    text-align: center;
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
}

.upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.upload-icon {
    font-size: 48px !important;
    color: #8e9195;
}

.file-input {
    display: none;
}

.action-icons {
    display: flex;
    justify-content: center;
}

.action-icon {
    width: 24px;
    height: 24px;
    color: #8e9195;
    cursor: pointer !important;
    transition: color 0.3s;
    margin: 0 5px;
}

.action-icon:hover {
    transition: color 0.3s;
}

.action-icons-brand {
    display: flex;
    justify-content: center;
}

.action-icon-brand {
    width: 24px;
    height: 24px;
    color: #34B1AA;
    cursor: pointer !important;
    transition: color 0.3s;
    margin: 0 5px;
}

.action-icon-brand:hover {
    transition: color 0.3s;
}

.action-icon-delete {
    width: 24px;
    height: 24px;
    color: #f44336c4;
    cursor: pointer !important;
    transition: color 0.3s;
    margin: 0 5px;
}

.action-icon-delete:hover {
    transition: color 0.3s;
}

.flex-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Adjust the gap as needed */
}

.flex-item {
    flex: 1;
}
