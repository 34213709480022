.running-modal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .running-modal-content {
    padding: 20px;
  }
  
  .running-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  